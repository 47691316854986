$header-height: 64px;

// For angular-calendar
@import '../../node_modules/angular-calendar/css/angular-calendar.css';

html,
body {
  min-height: 100vh !important;
  height: 100%;
}

html {
  background-color: #efeee2 !important;
}

body {
  margin: 0;
}

.page {
  padding: 1rem 1rem 1rem 1rem;
  height: calc(100vh - 64px - 1rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  box-sizing: border-box;
  overflow: auto;
  border-radius: 8px;
  background-color: #fbfaed;
}

.title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-family: Viga !important;
}

.large-title {
  font-size: 36px;
  color: var(--master);
}

.large-subtitle {
  font-size: 24px;
  color: var(--accent);
}

.normal-title {
  font-size: 28px;
  color: #1b1c15;
  margin: 1rem 0 2rem 0;
}

.normal-subtitle {
  font-size: 20px;
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
  padding: 0 0 15px 0;
  justify-content: unset;
  button {
    padding: 8px !important;
    height: 40px !important;
    width: 40px !important;
    margin: 0 5px 0 15px;
  }
}

.dialog-subtitle {
  font-size: 16px;
  color: #40484b;
}

.dialog-text {
  font-size: 14px;
}

.card-outline,
.card-filled,
.card-dashboard {
  border: solid 1px rgb(0, 0, 0, 0.3);
  box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px !important;
  padding: 0 !important;
}

.card-background {
  background-color: #fefcf4 !important;
  border: 1px solid rgba(0, 0, 0, 0.4) !important;
  border-radius: 24px !important;
  box-shadow: none !important;
}

.card-outline {
  border-radius: 24px !important;
  background-color: #fefcf4 !important;
  .warnings {
    color: var(--accent);
    position: absolute;
    right: 8px;
    top: 16px;

    mat-icon {
      margin-right: 8px;
    }
  }

  mat-card-content {
    height: 225px;
    border-radius: 24px;
    background-color: #efefef;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }
  .footer {
    padding: 0 16px 8px 16px;
    box-sizing: border-box;
  }
}

.card-filled {
  border-radius: 12px !important;
  background-color: #e5e3db !important;
  cursor: pointer !important;
  .warnings {
    color: var(--warn);
    position: absolute;
    right: 46px;
    top: 16px;
    z-index: 100;
  }
  mat-card-content {
    height: 225px;
    border-radius: 12px;
    margin: 0 0 16px 0;
    padding: 8px 0 4px 0 !important;
    background-color: #fbfaed;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    justify-content: center;
    align-items: center;
    .card-menu-button {
      z-index: 30;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  .footer {
    padding: 0 16px 8px 16px;
    box-sizing: border-box;
    color: #40484b;
    min-height: 60px;
  }
}

.card-filled-image {
  border-radius: 24px !important;
  background-color: #e5e3db !important;
  box-shadow: none !important;
  cursor: pointer;
  padding: 0 !important;
  width: 250px;
  .mat-card-content {
    .card-image {
      width: 100%;
      height: 188px;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border-top-left-radius: 24px;
      border-top-right-radius: 24px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    }
    .footer {
      padding: 15px;
      font-weight: 900;
      color: #40484b;
      font-size: 14px;
      min-height: 40px;
    }
  }
}

/* You can try to delete this code when you have updated to Angular 17. There is a mat-datepicker styling bug in Angular 16. */

.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de;
}

.mat-calendar-body-selected {
  background-color: #007f51;
  color: #fff;
}

.mat-calendar-body-cell-content {
  border: none !important;
}

// ----------------------------

// TODO replace all old global button rules
.outlined-button {
  display: flex !important;
  align-items: center !important;
  background-color: #fefcf4 !important;
  border: 1px solid #0000004d !important;
  color: var(--primary) !important;
  .mat-button-wrapper {
    line-height: normal !important;
    .icon {
      color: var(--primary) !important;
      border: 0 !important;
      margin-right: 0 !important;
    }
  }
}

.primary-button {
  color: var(--primary) !important;
}

.filled-button {
  background-color: var(--primary) !important;
  color: var(--primary-contrast) !important;
}

.disabled-button {
  background-color: rgba(0, 0, 0, 0.12) !important;
  color: rgba(0, 0, 0, 0.26) !important;
}

.filled-tonal-button {
  background-color: var(--accent-tonal) !important;
  color: var(--accent-tonal-contrast) !important;
  line-height: normal !important;
  width: inherit !important;
  .mat-button-wrapper {
    .icon {
      color: var(--accent-tonal-contrast) !important;
      border: 0 !important;
      margin-right: 0px !important;
    }
  }
}

// ------------------------------

.widget {
  background-color: #f3f1e9 !important;
  margin: 6px;
  .mat-card-header-text {
    margin: 0;
  }
  .mat-card-header .mat-card-title {
    margin-bottom: 6px;
  }
}

/* WebKit and Blink */
::-webkit-scrollbar {
  width: 6px;
  cursor: pointer;
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.4);
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:before {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #000000;
  animation: spinner 0.8s linear infinite;
}

.loading-wrapper {
  height: calc(100vh - 64px - 1rem);
  .loading {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

//TODO TO CHECK IF NEEDED
.page-height-unset {
  height: unset !important;
}

.mat-slide-toggle-content,
.mat-menu-item {
  font-family: Roboto, 'Helvetica Neue', sans-serif !important;
}

.mat-icon-button {
  line-height: normal !important;
}

*:not(mat-icon) {
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.mat-slide-toggle .mat-slide-toggle-ripple {
  top: calc(50% - 13px) !important;
  left: calc(50% - 13px) !important;
  height: 26px !important;
  width: 26px !important;
  border-radius: 50%;
}

.icon {
  height: 24px;
  color: rgba(0, 0, 0, 0.33);
  border-width: 1px;
  border-radius: 2px;
  border-style: solid;
  margin-right: 4px;
  padding: 2px;

  svg {
    height: 24px;
    width: 24px;
  }
}

.icon.active {
  color: var(--primary);
}
